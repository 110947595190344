export default [
    {
        path: "/campaign",
        name: "Landing",
        component: () => import("../Pages/Landing.vue")
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("../Pages/Login.vue")
    },
    {
        path: "/exclude-email",
        name: "ExcludeEmail",
        component: () => import("../Pages/ExcludeEmail.vue")
    },
    {
        path: "",
        component: () => import("../Pages/System.vue"),
        async beforeEnter(to, from, next) {
            try {
                await axios.get(`/api/verify`);

                next();
            } catch (error) {
                axios.defaults.headers.common["Authorization"] = null;
                localStorage.clear();
                next(`/login`);
            }
        },
        children: [
            {
                path: "/",
                name: "Home",
                component: () => import("../Pages/Home.vue"),
                meta: {permission: ['home']},
            },
            {
                path: "/clientes",
                name: "Customers",
                component: () => import("../Pages/Customers/List.vue"),
                meta: {permission: ['customers_list']},
            },
            {
                path: "/clientes/:type/:id?",
                name: "CustomersForm",
                component: () => import("../Pages/Customers/Form.vue"),
                meta: {permission: ['customers_create', 'customers_edit']},
            },
            {
                path: "/clientes-seguimiento/:id",
                name: "Tracing",
                component: () => import("../Pages/Customers/Tracing.vue"),
                meta: {permission: []},
            },
            {
                path: "/servicios",
                name: "Services",
                component: () => import("../Pages/Services/List.vue"),
                meta: {permission: ['services_index']},
            },
            {
                path: "/servicios/:type/:id?",
                name: "ServicesForm",
                component: () => import("../Pages/Services/Form.vue"),
                meta: {permission: ['services_create', 'services_edit']},
            },
            // {
            //     path: "/servicios-venta/:id/:saleId?",
            //     name: "ServiceSale",
            //     component: () => import("../Pages/Services/Sale.vue"),
            //     meta: {permission: []},
            // },
            // {
            //     path: "/venta-reporte",
            //     name: "ServiceReport",
            //     component: () => import("../Pages/Services/Report.vue"),
            //     meta: {permission: []},
            // },
            {
                path: "/estatus",
                name: "Status",
                component: () => import("../Pages/Status/List.vue"),
                meta: {permission: ['statuses_index']},
            },
            {
                path: "/estatus/:type/:id?",
                name: "StatusForm",
                component: () => import("../Pages/Status/Form.vue"),
                meta: {permission: ['statuses_create', 'statuses_edit']},
            },
            // {
            //     path: "/campo",
            //     name: "Field",
            //     component: () => import("../Pages/Field/List.vue"),
            //     meta: {permission: []},
            // },
            // {
            //     path: "/campo/:type/:id?",
            //     name: "FieldForm",
            //     component: () => import("../Pages/Field/Form.vue"),
            //     meta: {permission: []},
            // },
            {
                path: "/usuarios",
                name: "Users",
                component: () => import("../Pages/Users/List.vue"),
                meta: {permission: ['users_index']},
            },
            {
                path: "/usuarios/:type/:id?",
                name: "UsersForm",
                component: () => import("../Pages/Users/Form.vue"),
                meta: {permission: ['users_create', 'users_edit']},
            },
            // {
            //     path: "/cartera",
            //     name: "Wallet",
            //     component: () => import("../Pages/Wallet/List.vue"),
            //     meta: {permission: []},
            // },
        ]
    }
];
