import "sweetalert2/dist/sweetalert2.min.css";
import Vue from "vue";
import VueRouter from "vue-router";
import VueSweetalert2 from "vue-sweetalert2";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import router from "./Router";

window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["Content-Type"] = "application/json";

if (localStorage.getItem("access_token")) {
    window.axios.defaults.headers.common[
        "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
}

Vue.use(VueSweetalert2);
Vue.use(VueRouter);
Vue.use(Vuelidate);

export default new Vue({
    el: "#app",
    router,
    render: (h) => h(App),
});
